import logoRenaultGroup from 'assets/img/logo-renault.svg';
import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { ContextHeader } from 'screens/context/pages/ContextHeader';
import {
    Groupe, HomeLink, Renault, RenaultGroupeWrapper,
    RenaultGroupText,
    RenaultLogo, UserInfoWrapper
} from '../Styles';
import { TranslateMenu } from '../TranslateMenu/TranslateMenu';
import { HeaderMenu } from './HeaderMenu';

export const HeaderLogin: FC = () => {
    const { t } = useTranslation();

    return <>
        <RenaultGroupeWrapper>
            <HomeLink to="/home">
                <RenaultLogo src={logoRenaultGroup} alt="Renault logo" />
                <RenaultGroupText>
                    <Groupe>{t("Generic.group")}</Groupe>
                    <Renault>RENAULT</Renault>
                </RenaultGroupText>
            </HomeLink>
        </RenaultGroupeWrapper>
        <UserInfoWrapper>
            <ContextHeader />
            <TranslateMenu />
            <HeaderMenu />
        </UserInfoWrapper>
    </>
}