import { UserContextEntry } from "screens/login/stores/types"

export type BirCode = string | undefined

export interface Provider {
    repository: string
    accountId: string
    sia: string
}

export interface RoleState {
    allBIRCodeDataList?: BircodeData[]
    myBirCodeData?: BircodeData
    roleData: RoleData
    garagePopover: PopoverData
    orderPopover: OrderPopover
    rpartsStore: boolean
    userPopover: UserPopover
}

export interface PopoverData {
    isVisible: boolean
    value: string
}

export interface UserPopover {
    isVisible: boolean
    value?: UserContextEntry
    msg: string
}

export interface OrderPopover extends PopoverData {
    msg: string
}

export interface RoleData {
    myRolePrivilege: RolePrivilege
    myRoleList: RoleList
}

export interface BircodeData {
    bircode: BirCode
    businessName?: string
    city?: string
    databaseType?: DatabaseType
    countryCode?: string
}

export enum DatabaseType {
    ORACLE = "ORACLE",
    POSTGRE = "POSTGRE"
}

export type RoleList = Array<string>

/**
 * Define which privilege the user has.
 */
export enum RolePrivilege {
    /** Access denied. */
    NONE = 0,

    /** Access limited to his own seller : only 1 bircode. */
    R1 = 1,

    /** Access to all sellers from his country, but limited to account part : can choose any bircode in his country delimitation. */
    COUNTRY_SUBSCRIPTION = 2,

    /** Access to all sellers from his country : can choose any bircode in his country delimitation. */
    COUNTRY_ADMIN = 3,

    /** Access to all sellers : can choose any bircode. */
    CENTRAL_ADMIN = 4
}

export interface RoleMapping {
    [key: string]: RolePrivilege
}

export const roleMapping: RoleMapping = {

        // R1
        "1po-r1": RolePrivilege.R1,                                     // 1po (72795) arca
        "IRN-72795:commande": RolePrivilege.R1,                         // 1po (72795) rnet
        "commande": RolePrivilege.R1,                                   // 1po (72795) nb: without prefix when coming from Okta + Rnet with clientId 1PO
        "IRN-6943:commandes": RolePrivilege.R1,                         // rps (6943) rnet
        "IRN-6943:adhesion": RolePrivilege.R1,                          // rps (6943) rnet
        "IRN-6943:catalogue": RolePrivilege.R1,                         // rps (6943) rnet
        "R1": RolePrivilege.R1,                                         // rp4 (72189) arca

        // country sub
        "1po-country-subscription": RolePrivilege.COUNTRY_SUBSCRIPTION, // 1po (72795) arca
        "country_subscription": RolePrivilege.COUNTRY_SUBSCRIPTION,     // rp4 (72189) arca

        // country admin
        "1po-country-admin": RolePrivilege.COUNTRY_ADMIN,               // 1po (72795) arca
        "country_admin": RolePrivilege.COUNTRY_ADMIN,                   // rp4 (72189) arca

        // super admin
        "1po-central-admin": RolePrivilege.CENTRAL_ADMIN,               // 1po (72795) arca
        "IRN-6943:formateur-substitution": RolePrivilege.CENTRAL_ADMIN, // rps (6943) rnet
        "central_admin": RolePrivilege.CENTRAL_ADMIN                    // rp4 (72189) arca
};
