import { Action, handleActions } from 'redux-actions';
import { Country } from 'screens/accounts_oracle/stores/types';
import { RpUser, UserContextEntry } from 'screens/login/stores/types';
import { defaultUser, getUserFromLocalStorage, useDefaultUser } from 'shared/utils/utils';
import { BircodeData, DatabaseType, RoleData, RoleState } from '.';
import { RolePrivilege } from './types';
import { Type } from './actions';

const user = useDefaultUser ? new RpUser(defaultUser) : getUserFromLocalStorage();

const INITIAL_STATE: RoleState = {
    allBIRCodeDataList: undefined,
    myBirCodeData: !!user ? user.getBirCodeData() : undefined,

    roleData: !!user ? user.getRoleData() : { myRolePrivilege: RolePrivilege.NONE, myRoleList: [] },
    garagePopover: {
        isVisible: false,
        value: ""
    },
    orderPopover: {
        isVisible: false,
        value: "",
        msg: ""
    },
    rpartsStore: false,
    userPopover: {
        isVisible: false,
        value: undefined,
        msg: ""
    }
}

export default handleActions<RoleState, any>(
    {
        [Type.SET_ALL_BIRCODE_DATA_LIST]: (state: RoleState, action: Action<Array<Country>>) => {
            let countries = action.payload
            let bircodeDataList: Array<BircodeData> = []
            countries.forEach((country) => bircodeDataList = [...bircodeDataList, ...country.bircodeDataList])

            return {
                ...state,
                allBIRCodeDataList: bircodeDataList
            }
        },
        [Type.SET_MY_BIRCODE_DATA_SUCCESS]: (state: RoleState, action: Action<BircodeData>) => {
            const nextState = {
                ...state,
                myBirCodeData: action.payload,
                rpartsStore: action.payload.databaseType === DatabaseType.POSTGRE ?
                    true :
                    false
            };
            if (nextState.allBIRCodeDataList) {
                for (const bircodeData of nextState.allBIRCodeDataList) {
                    if (bircodeData.bircode !== action.payload.bircode) continue;
                    bircodeData.databaseType = action.payload.databaseType;
                    break;
                }
            }
            return nextState;
        },
        [Type.SET_TO_EMPTY_MY_BIRCODE_DATA]: (state: RoleState) => ({
            ...state,
            myBirCodeData: undefined
        }),
        [Type.SET_MY_ROLE_DATA]: (state: RoleState, action: Action<RoleData>) => ({
            ...state,
            roleData: action.payload
        }),
        [Type.SET_GARAGE_POPOVER_VISIBLE]: (state: RoleState, action: Action<boolean>) => ({
            ...state,
            garagePopover: {
                ...state.garagePopover,
                isVisible: action.payload
            }
        }),
        [Type.SET_GARAGE_POPOVER_VALUE]: (state: RoleState, action: Action<string>) => ({
            ...state,
            garagePopover: {
                ...state.garagePopover,
                value: action.payload
            }
        }),
        [Type.SET_ORDER_POPOVER_VISIBLE]: (state: RoleState, action: Action<boolean>) => ({
            ...state,
            orderPopover: {
                ...state.orderPopover,
                isVisible: action.payload
            }
        }),
        [Type.SET_ORDER_POPOVER_VALUE]: (state: RoleState, action: Action<string>) => ({
            ...state,
            orderPopover: {
                ...state.orderPopover,
                value: action.payload
            }
        }),
        [Type.SET_USER_POPOVER_VISIBLE]: (state: RoleState, action: Action<boolean>) => ({
            ...state,
            userPopover: {
                ...state.userPopover,
                isVisible: action.payload
            }
        }),
        [Type.SET_USER_POPOVER_VALUE]: (state: RoleState, action: Action<UserContextEntry>) => ({
            ...state,
            userPopover: {
                ...state.userPopover,
                value: action.payload
            }
        }),
        [Type.SET_ORDER_POPOVER_MSG]: (state: RoleState, action: Action<string>) => ({
            ...state,
            orderPopover: {
                ...state.orderPopover,
                msg: action.payload
            }
        }),
        [Type.SET_USER_POPOVER_MSG]: (state: RoleState, action: Action<string>) => ({
            ...state,
            userPopover: {
                ...state.userPopover,
                msg: action.payload
            }
        }),
        [Type.SET_RPARTS_STORE]: (state: RoleState, action: Action<boolean>) => ({
            ...state,
            rpartsStore: action.payload
        }),
    },
    INITIAL_STATE
);