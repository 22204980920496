import { RpUser } from './types';
import { createAction } from 'redux-actions';

export enum Type {
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED',
  AUTH_LOGIN_REFRESH_TOKEN = 'AUTH_LOGIN_REFRESH_TOKEN',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  USER_CONTEXT_SELECTED = 'USER_CONTEXT_SELECTED'
}

export const loginActions = {
  authLogin: createAction(Type.AUTH_LOGIN),
  authLoginSuccess: createAction<RpUser, RpUser>(Type.AUTH_LOGIN_SUCCESS, (user: RpUser) => user),
  authLoginFailed: createAction(Type.AUTH_LOGIN_FAILED),
  authLogout: createAction(Type.AUTH_LOGOUT),
  sidebarToggle: createAction(Type.TOGGLE_SIDEBAR),
  authLoginRefreshToken: createAction(Type.AUTH_LOGIN_REFRESH_TOKEN),
  userContextSelected: createAction(Type.USER_CONTEXT_SELECTED)
}
