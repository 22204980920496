import { DefaultOptionType } from "antd/es/select";
import store from "shared/stores/store";
import api, { unwrapAxiosResult } from "shared/utils/api";
import { API_URLs } from "shared/utils/constants";
import { contextActions } from "./actions";
import { BircodeData as BirCodeData, RolePrivilege } from "./types";
import { UserContextEntry } from "screens/login/stores/types";

const RP4_SUBSCRIBER = (birCode: string) => `${API_URLs.RP4_API}/referential/accounts/${birCode}/provider?repository=BIR`;

export const getRp4Subscriber = (birCode: string) =>
    unwrapAxiosResult(api.get(RP4_SUBSCRIBER(birCode)));

/**
 * @param requiredRolePrivilege Required Role Privilege to access ressource.
 * @returns boolean : True if granted, else is false.
 */
export const isRolePrivilegeGranted = (requiredRolePrivilege: RolePrivilege): boolean => {
    const myRolePrivilege: RolePrivilege = store.getState().rp4.context.roleData.myRolePrivilege;
    return myRolePrivilege >= requiredRolePrivilege
}

export const birCodeDataListToOptionList = (birCodeDataList: BirCodeData[]): DefaultOptionType[] => (
    birCodeDataList.map(x => birCodeDataToOption(x))
)

export const userContextListToOptionList = (entries: UserContextEntry[]): DefaultOptionType[] => (
    entries.map(x => UserContextEntryToOption(x))
)

export const birCodeDataToOption = (birCodeData: BirCodeData): DefaultOptionType => ({
    value: JSON.stringify(birCodeData),
    label: `${birCodeData.businessName} - ${birCodeData.bircode} - ${birCodeData.city}`,
    key: birCodeData.bircode,
})

export const UserContextEntryToOption = (entry: UserContextEntry): DefaultOptionType => ({
    value: JSON.stringify(entry),
    label: `${entry.bircode} - ${entry.company}`,
    key: `${entry.bircode}`
})

export const findBirCodeData = (birCode: string, birCodeDataList: BirCodeData[]): BirCodeData | undefined => {
    return birCodeDataList.find(x => x.bircode === birCode);
}

export const setBirCodeData = (birCode: string) => {
    let success = false;
    if (isRolePrivilegeGranted(RolePrivilege.COUNTRY_SUBSCRIPTION)) {
        const birCodeDataList = store.getState().rp4.context.allBIRCodeDataList;
        if (birCodeDataList) {
            const newBirCodeData = findBirCodeData(birCode, birCodeDataList)
            if (newBirCodeData) {
                store.dispatch(contextActions.setMyBirCodeData(newBirCodeData));
                success = true;
            }
        }
    }
    if (!success) store.dispatch(contextActions.setMyBirCodeDataError());
}