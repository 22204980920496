import { FC } from 'react';
import { GaragePopover } from '../components/GaragePopover';
import { OrderPopover } from '../components/OrderPopover';
import { UserPopover } from '../components/UserPopover';

export const ContextHeader: FC = () => {
    return (
        <>
            <GaragePopover />
            <OrderPopover />
            <UserPopover />
        </>
    );
};
