import {List } from "antd"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from 'react-router-dom'
import { SelectLabel, SelectWrapper } from "../Styles"
import { 
    userContextListToOptionList, 
    contextActions, 
    BIRCodeDataListSelector,
    userPopoverMsgSelector 
} from "../stores"
import { userSelector } from "../../login/stores/selectors";
import { RpUser, UserContextEntry } from "screens/login/stores/types"

export const UserSelect: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user: RpUser | undefined = useSelector(userSelector);
    const profiles = (!!user?.userContext && user?.userContext) || [];
    const setVisible = (visible: boolean) => dispatch(contextActions.setUserPopoverVisible(visible));
    const setValue = (value: UserContextEntry) => dispatch(contextActions.setUserPopoverValue(value));
    const bircodeDataList = useSelector(BIRCodeDataListSelector);
    const msg = useSelector(userPopoverMsgSelector);
    
    const options = userContextListToOptionList(profiles);

    const handleItemClick = (value: any) => {
        if (value) {
            const selectedProfile: UserContextEntry = JSON.parse(value);
            let found = false;
            if (!!bircodeDataList) {
                for (const bircodeData of bircodeDataList) {
                    if (bircodeData.bircode === selectedProfile.bircode) {
                        dispatch(contextActions.setMyBirCodeData(bircodeData));
                        setValue(selectedProfile);
                        found = true;
                        break;
                    }
                }
            }
            if (!found) {
                dispatch(contextActions.setUserPopoverMsg(`Unknown garage ${selectedProfile.bircode}`));
            }
            else {
                setVisible(false);
            }
            if (location.pathname !== "/home") {
                navigate("/home");
            }
        }
    };

    return (
        <SelectWrapper>
            <SelectLabel>{t(`Countries.chooseAGarage`)}</SelectLabel>
            <List
                bordered
                style={{ width: "450px" }}
                dataSource={options}
                renderItem={(item) => (
                    <List.Item
                        onClick={() => handleItemClick(item.value)}
                        style={{ cursor: 'pointer' }}
                    >
                        {item.label}
                    </List.Item>
                )}
            />
            {msg && <span style={{ color: "red" }}>{msg}</span>}
        </SelectWrapper>
    );
}