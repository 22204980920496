import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  contextActions,
  userPopoverVisibleSelector,
  userPopoverValueSelector,
} from '../stores'
import { Popover, PopoverProps } from 'antd'
import { HeaderToggleButton } from '../Styles'
import { UserSelect } from './UserSelect'
import { RpUser } from 'screens/login/stores/types'
import { userSelector } from "../../login/stores/selectors";

export const UserPopover: FC = () => {
  const dispatch = useDispatch();

  const user: RpUser | undefined = useSelector(userSelector);
  const multipleProfiles = !!user?.userContext && user.userContext.length > 0;
  const mainUserName = `${user?.ipn} - ${user?.firstName} ${user?.lastName?.toUpperCase()}`;
  const mainCompany = user?.company;

  const visible = useSelector(userPopoverVisibleSelector);
  const setVisible = (visible: boolean) => dispatch(contextActions.setUserPopoverVisible(visible));
  const value = useSelector(userPopoverValueSelector);

  const popoverProps: PopoverProps = {
    destroyTooltipOnHide: true,
    open: visible && multipleProfiles,
    trigger: "click",
    placement: "top",
    onOpenChange: () => setVisible(!visible),
    content: <UserSelect />
  }

  //if (roles === undefined || !isRolePrivilegeGranted(RolePrivilege.COUNTRY_SUBSCRIPTION)) return <></>

  const res = () => {
    if (!!value) {
      return mainUserName + ' | ' + (!!value.bircode ? value.bircode + ' | ' : '') + value.company;
    }
    return mainUserName + ' | ' + (!!(user?.getBirCodeData()?.bircode) ? user.getBirCodeData()?.bircode + ' | ' : '' ) + mainCompany;  }

  return <Popover {...popoverProps}>
    <HeaderToggleButton>
      {res()}
    </HeaderToggleButton>
  </Popover>
};