import { FileExcelTwoTone, UploadOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Row, Tabs, Upload, UploadProps, message } from "antd"
import { RcFile, UploadFile } from "antd/lib/upload/interface"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { CustomButton } from "screens/accounts_postgre/Styles"
import { userSelector } from "screens/login/stores/selectors"
import { PageContainer } from "shared/GlobalStyles"
import { FormWrapper } from "shared/components/Form/FormWrapper"
import { CenteredSpin, DetailsRow } from "shared/components/Styles"
import { UploadFilePayload, actionCreators, exadisLoading, exadisResponse } from "../stores"

const TEMPLATE_URL = "/static/media/Template_Commande_Stock_Exadis.xlsx";

const ExadisTracking: FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const user = useSelector(userSelector);
    const form = Form.useForm();

    const loading = useSelector(exadisLoading)
    const response = useSelector(exadisResponse);
    const [showResponse, setShowResponse] = useState(false);

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const handleUpload = (values: UploadFilePayload) => {
        dispatch(actionCreators.uploadFile({
            email: values.email,
            file: fileList[0] as RcFile
        }))
        setFileList([])
        setShowResponse(true);
    };

    useEffect(() => {
        if (response && showResponse) {
            if (response.status === "done") {
                message.success("Fichier " + response.name + " uploadé !");
            }
            if (response.status === "error") {
                message.error("Echec de l'upload du fichier")
            }

            setShowResponse(false)
        }
    }, [response, showResponse])

    const props: UploadProps = {
        onRemove: () => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            const isXLSX = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const isTXT = file.type === "text/plain";
            const isCSV = file.type === "text/csv";

            if (!(isXLSX || isTXT || isCSV)) {
                message.error(`Format du fichier ${file.name} non pris en charge. Le fichier doit être au format csv, xlsx ou txt`);
                setFileList([]);
                return false;
            }

            setFileList([file]);
            return false;
        },
        fileList,
        maxCount: 1,
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, text/plain"
    };

    return <PageContainer>
        <Tabs>
            <Tabs.TabPane tab={"Exadis"}>
                <DetailsRow>
                    {loading && <CenteredSpin size="large" />}
                    {!loading && <FormWrapper
                        form={form[0]}
                        onFinish={handleUpload}
                        initialValues={{
                            "email": user?.email
                        }}>
                        <Row gutter={16}>
                            <Col span={4}>
                                <span>Email :</span>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    rules={[{ required: true, type: "email" }]}
                                    name='email'>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={4}>
                                <span>Fichier :</span>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    rules={[{ required: true }]}
                                    name='file'>
                                    <Upload {...props}>
                                        <Button
                                            icon={<UploadOutlined />}>
                                            Upload
                                        </Button>
                                        <a style={{
                                            marginLeft: "15px"
                                        }} href={TEMPLATE_URL}>Télécharger le template de commande <FileExcelTwoTone /></a>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <CustomButton disabled={fileList.length === 0} type="primary" htmlType="submit">{t("Generic.Actions.Validate")}</CustomButton>
                    </FormWrapper>}
                </DetailsRow>
            </Tabs.TabPane>
        </Tabs>
    </PageContainer>
}

export default ExadisTracking
