import { createAction } from "redux-actions";
import { Country as CountryOracle } from "screens/accounts_oracle/stores";
import { BircodeData, RoleData } from ".";
import { UserContextEntry } from "screens/login/stores/types";

export enum Type {
    SET_MY_BIRCODE_DATA = 'SET_MY_BIRCODE_DATA',
    SET_MY_BIRCODE_DATA_SUCCESS = 'SET_MY_BIRCODE_DATA_SUCCESS',
    SET_MY_BIRCODE_DATA_ERROR = 'SET_MY_BIRCODE_DATA_ERROR',
    SET_TO_EMPTY_MY_BIRCODE_DATA = 'SET_TO_EMPTY_MY_BIRCODE_DATA',
    SET_MY_ROLE_DATA = "SET_MY_ROLE_DATA",
    SET_GARAGE_POPOVER_VISIBLE = "SET_GARAGE_POPOVER_VISIBLE",
    SET_GARAGE_POPOVER_VALUE = "SET_GARAGE_POPOVER_VALUE",
    SET_ORDER_POPOVER_VISIBLE = "SET_ORDER_POPOVER_VISIBLE",
    SET_ORDER_POPOVER_VALUE = "SET_ORDER_POPOVER_VALUE",
    SET_ORDER_POPOVER_MSG = "SET_ORDER_POPOVER_MSG",
    SET_USER_POPOVER_VISIBLE = "SET_USER_POPOVER_VISIBLE",
    SET_USER_POPOVER_VALUE = "SET_USER_POPOVER_VALUE",
    SET_USER_POPOVER_MSG = "SET_USER_POPOVER_MSG",
    SET_ALL_BIRCODE_DATA_LIST = "SET_ALL_BIRCODE_DATA_LIST",
    SET_RPARTS_STORE = "SET_RPARTS_STORE",
}

export const contextActions = {
    setMyBirCodeData: createAction<BircodeData, BircodeData>(Type.SET_MY_BIRCODE_DATA, (payload: BircodeData) => payload),
    setMyBirCodeDataSuccess: createAction<BircodeData, BircodeData>(Type.SET_MY_BIRCODE_DATA_SUCCESS, (payload: BircodeData) => payload),
    setMyBirCodeDataError: createAction(Type.SET_MY_BIRCODE_DATA_ERROR),
    setToEmptyMyBirCode: createAction(Type.SET_TO_EMPTY_MY_BIRCODE_DATA),
    setMyRole: createAction<RoleData, RoleData>(Type.SET_MY_ROLE_DATA, (payload: RoleData) => payload),
    setGaragePopoverVisible: createAction<boolean, boolean>(Type.SET_GARAGE_POPOVER_VISIBLE, (payload: boolean) => payload),
    setGaragePopoverValue: createAction<string, string>(Type.SET_GARAGE_POPOVER_VALUE, (payload: string) => payload),
    setOrderPopoverVisible: createAction<boolean, boolean>(Type.SET_ORDER_POPOVER_VISIBLE, (payload: boolean) => payload),
    setOrderPopoverValue: createAction<string, string>(Type.SET_ORDER_POPOVER_VALUE, (payload: string) => payload),
    setOrderPopoverMsg: createAction<string, string>(Type.SET_ORDER_POPOVER_MSG, (payload: string) => payload),
    setUserPopoverVisible: createAction<boolean, boolean>(Type.SET_USER_POPOVER_VISIBLE, (payload: boolean) => payload),
    setUserPopoverValue: createAction<UserContextEntry, UserContextEntry>(Type.SET_USER_POPOVER_VALUE, (payload: UserContextEntry) => payload),
    setUserPopoverMsg: createAction<string, string>(Type.SET_USER_POPOVER_MSG, (payload: string) => payload),
    setAllBIRCodeDataList: createAction<CountryOracle[], CountryOracle[]>(Type.SET_ALL_BIRCODE_DATA_LIST, (payload: CountryOracle[]) => payload),
    setRpartsStore: createAction<boolean, boolean>(Type.SET_RPARTS_STORE, (payload: boolean) => payload)
}