import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { myBIRCodeDataSelector, myRolePrivilegeSelector, RolePrivilege } from "screens/context/stores";
import { userSelector } from "../stores/selectors";
import { BirCode } from "screens/context/stores";

export function useBIRCode(): BirCode {
    const myRolePrivilege = useSelector(myRolePrivilegeSelector)
    const user = useSelector(userSelector)
    const BIRCodeData = useSelector(myBIRCodeDataSelector);

    const [birCode, setBirCode] = useState(BIRCodeData?.bircode)

    useEffect(() => {
        if (myRolePrivilege === RolePrivilege.R1) {
            setBirCode(user?.bircodeFromUser)
        }
    }, [myRolePrivilege, user?.bircodeFromUser])

    return birCode
}